<template>
  <div
    style="height: 100vh;
    width: 100%;
    overflow-y: scroll;"
  >
    <!-- <div class="container">
      <p class="mymsg">挂号记录</p>
    </div> -->

    <div class="content-box">
      <div class="content" v-for="(item, index) in order" :key="index">
        <p class="content-title">
          <strong>{{ item.Department }}</strong>
          <span class="btnMap" @click="btnMap(item.SessionName)"
            ><img src="../assets/images/address.png" alt="" />
            <i>去这里</i></span
          >
          <img
            class="tuihao"
            v-if="item.Status == '退号'"
            src="https://hrp.weilaihos.com/uploads/20210121/82529bafa7b8675c734813874ee372fe.png"
            alt=""
          />
        </p>
        <p><em class="gray">医生姓名</em>{{ item.Doctor }}</p>
        <p><em class="gray">就诊医院</em>{{ item.HospitalName }}</p>
        <p>
          <em class="gray" style="letter-spacing: 8px;margin-right:6px"
            >就诊人</em
          >{{ item.PatName }}
        </p>
        <p><em class="gray">挂号日期</em>{{ item.customTime }}</p>
        <button
          class="btn"
          v-if="index === checkState || item.Status !== '退号'"
          @click="tuihao(index, item)"
        >
          退号
        </button>
      </div>
    </div>
    <div class="empty" v-if="empty">
      <img
        src="https://hrp.weilaihos.com/uploads/20210121/98afe4a6068ee7b9cf59bbf25fb16377.png"
        alt=""
      />
      <text class="empty-text">暂无更多数据</text>
    </div>
    <van-loading class="load" color="#1989fa" size="50" v-if="isLoading"></van-loading>
  </div>
</template>

<script>
import jweixin from 'jweixin-module'
import { Dialog, Toast } from 'vant'
import {
  getFamilyOrderDetail,
  getFamily,
  getOrderExit
  // getMap
} from '../services/home'
import instance from '../utils/request.js'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      order: [],
      current: 0,
      active: 0,
      list: [],
      nopay: [],
      checkState: null,
      orderList1: [],
      empty: false,
      listMap: [],
      isLoading: true
    }
  },
  async created() {
    const fojz = await getFamily(this.$route.query.user_id)
    var now = new Date()
    var future = new Date()
    future.setDate(future.getDate() + 7)
    function plus0(number) {
      return number < 10 ? '0' + number : number
    }
    const wezhen = fojz.data.data.rows
    let questRes = []

    for (var i = 0; i < wezhen.length; i++) {
      var orderListRes = await getFamilyOrderDetail(
        '<Request>' +
          '<TradeCode>1104</TradeCode>' +
          '<ExtOrgCode></ExtOrgCode>' +
          '<ClientType></ClientType>' +
          '<HospitalId></HospitalId>' +
          '<ExtUserID>wechat002</ExtUserID>' +
          '<CardType></CardType>' +
          '<PatientCard></PatientCard>' +
          '<PatientID>' +
          wezhen[i].patient_id +
          '</PatientID>' +
          '<StartDate>' +
          (now.getFullYear() - 1) +
          '-' +
          plus0(now.getMonth() + 1) +
          '-' +
          plus0(now.getDate()) +
          '</StartDate>' +
          '<EndDate>' +
          future.getFullYear() +
          '-' +
          plus0(future.getMonth() + 1) +
          '-' +
          plus0(future.getDate()) +
          '</EndDate>' +
          '<TransactionId></TransactionId>' +
          '<AdmNo></AdmNo>' +
          '</Request>'
      )
      if (orderListRes.data.Response.ResultCode === '0') {
        var orderList = orderListRes.data.Response.Orders.Order
        if (toString.call(orderList) === '[object Array]') {
          questRes = [...orderList, ...questRes]
        } else {
          questRes.push(Object.assign({}, orderList))
        }
      }
    }

    for (var m = 0; m < questRes.length; m++) {
      const resMap = await instance({
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        url: 'https://msg.weilaihos.com/msg-service/map/getMapName',
        data: {
          hisName: questRes[m].Department
        },
        method: 'POST'
      })
      this.listMap.push({ hisName: resMap.data.data })
    }
    questRes = questRes.map((item, i) => {
      const { RegDate } = item
      console.log(RegDate)
      item.customTime = RegDate.replace('^', ' ')
      item.SessionName = this.listMap[i].hisName
      item.RegDate = new Date(item.RegDate.replace('^', ' ')).getTime()
      return item
    })

    for (var j = 0; j < questRes.length - 1; j++) {
      for (var k = 0; k < questRes.length - 1 - j; k++) {
        if (questRes[k + 1].RegDate > questRes[k].RegDate) {
          var temp = questRes[k].RegDate
          questRes[k].RegDate = questRes[k + 1].RegDate
          questRes[k + 1].RegDate = temp
        }
      }
    }

    const someArr = questRes.sort((a, b) => {
      return a.RegDate - b.RegDate
    })

    this.order = someArr
    console.log(this.order)
    if (this.order.length === 0) {
      this.empty = true
    }
    this.delay()
  },
  methods: {
    tuihao(index, item) {
      const { Status: status } = item
      if (status === '正常') {
        Dialog.confirm({
          message: '是否退号？'
        })
          .then(() => {
            getOrderExit(
              '<Request>' +
                '<TradeCode>1003</TradeCode>' +
                '<ExtOrgCode></ExtOrgCode>' +
                '<ClientType></ClientType>' +
                '<HospitalId></HospitalId>' +
                '<ExtUserID>wechat002</ExtUserID>' +
                '<AdmNo>' +
                this.order[index].AdmNo +
                '</AdmNo>' +
                '<TransactionId></TransactionId>' +
                '<RefundType></RefundType>' +
                '</Request>'
            ).then(res => {
              if (res.data.Response.ResultCode === '0') {
                Toast(res.data.Response.ResultContent)
                this.order[index].Status = '退号'
                instance({
                  method: 'POST',
                  url: 'addons/ask/Order/getNoRegister',
                  data: {
                    user_id: this.$route.query.user_id
                  }
                })
                  .then(result => {
                    console.log(result, '取消挂号-减积分请求结果')
                  })
                  .catch(err => {
                    console.error('取消挂号-减积分请求出现错误', err)
                  })
              } else {
                Toast(res.data.Response.ResultContent)
              }
            })
          })
          .catch(() => {
            //
          })
      } else {
        // this.order.splice(index, 1)
        this.checkState = null
      }
    },

    btnMap(name) {
      console.log(name)
      const departName = name
      jweixin.miniProgram.getEnv(function(res) {
        if (res.miniprogram) {
          jweixin.miniProgram.navigateTo({
            url: '/pages/openMap/openMap?departName=' + departName
          }) // 跳转到小程序调用
        } else {
          // 非小程序环境下逻辑
          console.log('非小程序环境下逻辑')
        }
      })
    },

    delay() {
      setTimeout(() => {
        this.isLoading = false
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">
.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.empty {
  height: 100vh;
  width: 100%;
  text-align: center;
  background: #ffffff;
  color: #909399;
  img {
    display: block;
    width: 375px;
    height: 293px;
  }
  .empty-text {
    width: 100%;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 25px;
    position: absolute;
    top: 230px;
    left: 0px;
    text-align: center;
  }
}

.nav {
  position: absolute;
  top: 44px;
  width: 100%;
  height: 9vw;
}
.nav-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.bgColors {
  color: #5ca5ff;
  border-bottom: 2px solid #5ca5ff;
  padding-bottom: 2vw;
}
.content-box {
  background: #f9f9f9;
  width: 100%;
  .content {
    width: 92vw;
    padding: 5px 14px;
    overflow: hidden;
    background: #ffffff;
    border: 0.1px solid #eeeeee;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 8px auto;
    p {
      .gray {
        color: #999999;
        margin-right: 15px;
      }
    }
    .content-title {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 0.1px solid #eeeeee;
      padding: 1px 0 9px 0;
      margin: 10px 0;
      line-height: 22px;
      position: relative;
      .btnMap {
        font-size: 14px;
        width: 60px;
        color: #5ca5ff;
        z-index: 10;
        margin-left: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 12px;
          height: 14px;
          margin-right: 2px;
        }
      }
      strong {
        font-weight: bold;
      }
      .tuihao {
        display: block;
        width: 78px;
        height: 78px;
        position: absolute;
        right: -3px;
        top: -9px;
      }
    }
  }
}
em,
i {
  font-style: normal;
}
.money {
  font-weight: bold;
  font-style: normal;
}
.btn {
  float: right;
  margin: 5px;
  width: 80px;
  height: 38px;
  background: #4397ff;
  border-radius: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  border: 0;
  line-height: 38px;
}
.mymsg {
  margin: 0 !important;
}
</style>
